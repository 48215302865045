"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCurentYear = void 0;
function setCurentYear() {
    const years = document.querySelectorAll('time.year');
    years.forEach(year => {
        year.innerHTML = new Date().getFullYear().toString();
    });
}
exports.setCurentYear = setCurentYear;
