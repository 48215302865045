"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const navigation_1 = require("./navigation");
const map_1 = require("./map");
const boxAnim_1 = require("./boxAnim");
const year_1 = require("./year");
const email_1 = require("./email");
document.addEventListener("DOMContentLoaded", function () {
    (0, navigation_1.initNav)();
    (0, boxAnim_1.initAnim)();
    (0, year_1.setCurentYear)();
    (0, email_1.initEmail)();
});
document.addEventListener('scroll', () => {
    if (!document.body.classList.contains('map-init')) {
        (0, map_1.initMap)();
    }
    else
        return;
});
